import React from 'react'
import './clubbodyimage.css'

function ClubBodyImage({item, current}) {
  let active = ((current === item.id) ? 'active-image' : "")
  return (
    <React.Fragment>
      <div className="mini-club-title">
        {item.title}
      </div>
      <div className="mini-club-text">
          <p>{item.text}</p>
      </div>
      <div className={`club-up-right-box-item ${active}`}>
        <img src={item.img} alt="KK Blokovi"/>
      </div>
    </React.Fragment>
  )
}

export default ClubBodyImage
