export const mediadata = [
  {
    id: 14000,
    title: "Jastrebac - 2021",
    text: "KK Blokovi Kamp II 2021",
    items: [
      {
        id: 1410,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-1.jpg"
      },
      {
        id: 1400,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-2.jpg"
      },
      {
        id: 1401,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-3.jpg"
      },
      {
        id: 1402,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-4.jpg"
      },
      {
        id: 1403,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-5.jpg"
      },
      {
        id: 1404,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-6.jpg"
      },
      {
        id: 1405,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-7.jpg"
      },
      {
        id: 1406,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-8.jpg"
      },
      {
        id: 1407,
        media: 'img',
        img: "images/media/jastrebac/jastrebac-9.jpg"
      },
      {
        id: 1408,
        media: 'video',
        img: "images/media/jastrebac/jastrebac-1.mp4"
      },
      {
        id: 1409,
        media: 'video',
        img: "images/media/jastrebac/jastrebac-2.mp4"
      },
      {
        id: 1411,
        media: 'video',
        img: "images/media/jastrebac/jastrebac-4.mp4"
      },
      {
        id: 1412,
        media: 'video',
        img: "images/media/jastrebac/jastrebac-5.mp4"
      },
      {
        id: 1413,
        media: 'img',
        img: "images/media/jastrebac/kamp-1.jpg"
      },
      {
        id: 1414,
        media: 'img',
        img: "images/media/jastrebac/kamp-2.jpg"
      },{
        id: 1415,
        media: 'img',
        img: "images/media/jastrebac/kamp-3.jpg"
      },{
        id: 1416,
        media: 'img',
        img: "images/media/jastrebac/kamp-4.jpg"
      },{
        id: 1417,
        media: 'img',
        img: "images/media/jastrebac/kamp-5.jpg"
      },{
        id: 1418,
        media: 'img',
        img: "images/media/jastrebac/kamp-6.jpg"
      },{
        id: 1419,
        media: 'img',
        img: "images/media/jastrebac/kamp-7.jpg"
      },{
        id: 1420,
        media: 'img',
        img: "images/media/jastrebac/kamp-8.jpg"
      },{
        id: 1421,
        media: 'img',
        img: "images/media/jastrebac/kamp-9.jpg"
      },{
        id: 1422,
        media: 'img',
        img: "images/media/jastrebac/kamp-10.jpg"
      },{
        id: 1423,
        media: 'img',
        img: "images/media/jastrebac/kamp-11.jpg"
      },{
        id: 1424,
        media: 'video',
        img: "images/media/jastrebac/kamp-1.m4v"
      },{
        id: 1425,
        media: 'video',
        img: "images/media/jastrebac/kamp-1.m4v"
      },
    ]
  },
  {
    id: 2000,
    title: "Mataruška Banja-2021",
    text: "KK Blokovi Kamp 2021",
    items: [
      {
        id: 1200,
        media: 'img',
        img: "images/media/mataruska2021/mata-1.jpg"
      },
      {
        id: 1201,
        media: 'img',
        img: "images/media/mataruska2021/mata-2.jpg"
      },
      {
        id: 1202,
        media: 'img',
        img: "images/media/mataruska2021/mata-3.jpg"
      },
      {
        id: 1203,
        media: 'img',
        img: "images/media/mataruska2021/mata-4.jpg"
      },
      {
        id: 1204,
        media: 'img',
        img: "images/media/mataruska2021/mata-5.jpg"
      },
      {
        id: 1205,
        media: 'img',
        img: "images/media/mataruska2021/mata-6.jpg"
      },
      {
        id: 1207,
        media: 'img',
        img: "images/media/mataruska2021/mata-7.jpg"
      },
      {
        id: 1208,
        media: 'img',
        img: "images/media/mataruska2021/mata-8.jpg"
      },
      {
        id: 1210,
        media: 'video',
        img: "images/media/mataruska2021/mata-v1.m4v"
      },
      {
        id: 1211,
        media: 'video',
        img: "images/media/mataruska2021/mata-v2.m4v"
      },
      {
        id: 1212,
        media: 'video',
        img: "images/media/mataruska2021/mata-v3.m4v"
      },
    ]
  },
  {
    id: 0,
    title: "Orašac-2020",
    text: "Ovog leta smo imali toliku želju za  treniranjem da smo organizovali dva košarkaška kampa!",
    items: [
      {
        id: 1100,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-1.jpg"
      },
      {
        id: 1101,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-2.jpg"
      },
      {
        id: 1102,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-3.jpg"
      },
      {
        id: 1103,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-4.jpg"
      },
      {
        id: 1104,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-5.jpg"
      },
      {
        id: 1105,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-6.jpg"
      },
      {
        id: 1107,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-7.jpg"
      },
      {
        id: 1108,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-8.jpg"
      },
      {
        id: 1110,
        media: 'img',
        img: "images/media/orasac2020/orasac2020-9.jpg"
      },
      {
        id: 1111,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v1.m4v"
      },
      {
        id: 1112,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v2.m4v"
      },
      {
        id: 1113,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v3.m4v"
      },
      {
        id: 1114,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v4.m4v"
      },
      {
        id: 1115,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v5.m4v"
      },
      {
        id: 1116,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v6.m4v"
      },
      {
        id: 1117,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v7.m4v"
      },
      {
        id: 1118,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v8.m4v"
      },
      {
        id: 1120,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v10.m4v"
      },
      {
        id: 1121,
        media: 'video',
        img: "images/media/orasac2020/orasac2020-v11.m4v"
      },
    ]
  },
  {
    id: 1,
    title: "Guča-2020",
    text: "Ovog leta smo imali toliku želju za  treniranjem da smo organizovali dva košarkaška kampa!",
    items: [
      {
        id: 100,
        media: 'img',
        img: "images/media/guca2020/guca2020-1-bg.jpg"
      },
      {
        id: 101,
        media: 'img',
        img: "images/media/guca2020/guca2020-2-bg.jpg"
      },
      {
        id: 102,
        media: 'img',
        img: "images/media/guca2020/guca2020-3-bg.jpg"
      },
      {
        id: 103,
        media: 'img',
        img: "images/media/guca2020/guca2020-4-bg.jpg"
      },
      {
        id: 104,
        media: 'img',
        img: "images/media/guca2020/guca2020-5-bg.jpg"
      },
      {
        id: 105,
        media: 'img',
        img: "images/media/guca2020/guca2020-6-bg.jpg"
      },
      {
        id: 107,
        media: 'img',
        img: "images/media/guca2020/guca2020-8-bg.jpg"
      },
      {
        id: 108,
        media: 'img',
        img: "images/media/guca2020/guca2020-9-bg.jpg"
      },
      {
        id: 110,
        media: 'img',
        img: "images/media/guca2020/guca2020-11-bg.jpg"
      },
      {
        id: 111,
        media: 'img',
        img: "images/media/guca2020/guca2020-12-bg.jpg"
      },
      {
        id: 112,
        media: 'img',
        img: "images/media/guca2020/guca2020-13-bg.jpg"
      },
      {
        id: 113,
        media: 'img',
        img: "images/media/guca2020/guca2020-14-bg.jpg"
      },
      {
        id: 114,
        media: 'img',
        img: "images/media/guca2020/guca2020-15-bg.jpg"
      },
      {
        id: 115,
        media: 'img',
        img: "images/media/guca2020/guca2020-16-bg.jpg"
      },
      {
        id: 116,
        media: 'img',
        img: "images/media/guca2020/guca2020-17-bg.jpg"
      },
      {
        id: 117,
        media: 'img',
        img: "images/media/guca2020/guca2020-18-bg.jpg"
      },
      {
        id: 118,
        media: 'img',
        img: "images/media/guca2020/guca2020-19-bg.jpg"
      },
      {
        id: 119,
        media: 'img',
        img: "images/media/guca2020/guca2020-20-bg.jpg"
      },
      {
        id: 120,
        media: 'img',
        img: "images/media/guca2020/guca2020-21-bg.jpg"
      },
      {
        id: 121,
        media: 'img',
        img: "images/media/guca2020/guca2020-22-bg.jpg"
      },
      {
        id: 122,
        media: 'video',
        img: "images/media/guca2020/guca2020-v1.mp4"
      },
      {
        id: 123,
        media: 'video',
        img: "images/media/guca2020/guca2020-v2.mp4"
      },
     
    ]
  },
  {
    id: 2,
    title: "Lučani-2019",
    text: "I ove godine smo radili naporno i napredak je očigledan!",
    items: [
      {
        id: 1,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-1-bg.jpg"
      },
      {
        id: 2,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-2-bg.jpg"
      },
      {
        id: 3,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-3-bg.jpg"
      },
      {
        id: 4,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-4-bg.jpg"
      },
      {
        id: 5,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-5-bg.jpg"
      },
      {
        id: 6,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-6-bg.jpg"
      },
      {
        id: 7,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-7-bg.jpg"
      },
      {
        id: 8,
        media: 'img',
        img: "images/media/lucani2019/lucani2019-8-bg.jpg"
      }
    ]
  },
  {
    id: 3,
    title: "Lučani - 2018",
    text: "Ove godine smo program kampa upotpunili radom u teretani na najmodernijim spravama...",
    items: [
      {
        id: 9,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-1.jpg"
      },
      {
        id: 10,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-2.jpg"
      },
      {
        id: 11,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-3.jpg"
      },
      {
        id: 12,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-4.jpg"
      },
      {
        id: 13,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-5.jpg"
      },
      {
        id: 14,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-6.jpg"
      },
      {
        id: 15,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-7.jpg"
      },
      {
        id: 16,
        media: 'img',
        img: "images/media/lucani2018/lucani2018-8.jpg"
      },
    ]
  },
  {
    id: 4,
    title: "Lučani - 2017",
    text: "E tada smo već bili brojniji i spremali smo se za prvu takmičarsku sezonu.",
    items: [
      {
        id: 17,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-1-bg.jpg"
      },
      {
        id: 18,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-2-bg.jpg"
      },
      {
        id: 19,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-3-bg.jpg"
      },
      {
        id: 20,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-4-bg.jpg"
      },
      {
        id: 21,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-5-bg.jpg"
      },
      {
        id: 22,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-6-bg.jpg"
      },
      {
        id: 23,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-7.jpg"
      },
      {
        id: 24,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-8.jpg"
      },
      {
        id: 25,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-9.jpg"
      },
      {
        id: 26,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-10.jpg"
      },
      {
        id: 27,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-11.jpg"
      },
      {
        id: 28,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-12.jpg"
      },
      {
        id: 29,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-13.jpg"
      },
      {
        id: 30,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-14.jpg"
      },
      {
        id: 31,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-15.jpg"
      },
      {
        id: 32,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-16.jpg"
      },
      {
        id: 33,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-17.jpg"
      },
      {
        id: 34,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-18.jpg"
      },
      {
        id: 35,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-19.jpg"
      },
      {
        id: 36,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-20.jpg"
      },
      {
        id: 37,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-21.jpg"
      },
      {
        id: 38,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-22.jpg"
      },
      {
        id: 39,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-23.jpg"
      },
      {
        id: 40,
        media: 'img',
        img: "images/media/lucani2017/lucani2017-24.jpg"
      }
    ]
  },
  {
    id: 5,
    title: "Soko-Banja-2016",
    text: "Na prvom kampu nas nije bilo mnogo, ali smo bili odabrani...",
    items: [
      {
        id: 41,
        media: 'img',
        img: "images/media/sokobanja2016/sokobanja2016-1-bg.jpg"
      },
      {
        id: 42,
        media: 'img',
        img: "images/media/sokobanja2016/sokobanja2016-2-bg.jpg"
      },
      {
        id: 43,
        media: 'img',
        img: "images/media/sokobanja2016/sokobanja2016-3-bg.jpg"
      },
    ]
  },
  {
    id: 6,
    title: "Početak - 2016",
    text: "Svaki početak je težak... ali smo uživali znajući da radimo dobru i ispravnu stvar...",
    items: [
      {
        id: 44,
        media: 'img',
        img: "images/media/pocetak/pocetak-1-bg.jpg"
      },
      {
        id: 45,
        media: 'img',
        img: "images/media/pocetak/pocetak-2-bg.jpg"
      },
      {
        id: 46,
        media: 'img',
        img: "images/media/pocetak/pocetak-3-bg.jpg"
      },
      {
        id: 47,
        media: 'img',
        img: "images/media/pocetak/pocetak-4-bg.jpg"
      },
      {
        id: 48,
        media: 'img',
        img: "images/media/pocetak/pocetak-5-bg.jpg"
      },
      {
        id: 49,
        media: 'img',
        img: "images/media/pocetak/pocetak-6-bg.jpg"
      },
    ]
  }
]