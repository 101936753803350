import React from 'react'

import './mails.css'

function Mails() {
  return (
    <div className="mails">
      <div className="mails-title">
        MEILOVI KLUBA
      </div>
      <div className="mails-line">
        <div className="mail-title">ŠKOLA KOŠARKE</div>
        <div className="mail-mail">skolakosarke@kkblokovi.rs</div>
      </div>

      <div className="mails-line">
        <div className="mail-title">MARKETING-TEAM</div>
        <div className="mail-mail">marketing@kkblokovi.rs</div>
      </div>

      <div className="mails-line">
        <div className="mail-title">KANCELARIJA</div>
        <div className="mail-mail">office@kkblokovi.rs</div>
      </div>
    </div>
  )
}

export default Mails

