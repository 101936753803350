import React from'react'

import './sponsor.css'

function Sponsor(props) {
  const {item} = props

  return (
    <div key = {item.id} className="sponsor-item">
      <a href={item.url}>
        <img src={ item.img } alt={item.title} />
      </a>
    </div>
  )
}

export default Sponsor
