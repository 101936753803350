import React, {useRef, useState} from 'react'
import TinySlider from 'tiny-slider-react'
import ShopHeader from './ShopHeader'
import ShopItem from './ShopItem'
import OrderForm from './OrderForm'

import {shopItems} from './shopData'
import './shop.css'

function Shop() {
  const [item, setItem] = useState({view: false, img: "", art: "", title:""} )
  const ts = useRef(null)
  
  const settings = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    controls: false,
    gutter: 20,
    items: 4,
    fixedWidth: 270,
    viewportMax: 1160,
    center: false,
    autoplayButtonOutput: false,
    speed: 700,
    slideBy: 2,
    loop: false
  }
  
  
  const onGoTo = dir => ts.current.slider.goTo(dir)

   function handleCancel() {
    setItem({ view: false})
  }

  function handleBuy(e) {
    setItem({
      view: true,
      img: e.target.dataset.img,
      art: e.target.dataset.art,
      title: e.target.dataset.title
    })
  } 

  function handleNav(e) {
    e.currentTarget.dataset.dir === "1" && onGoTo('next') 
    e.currentTarget.dataset.dir === "-1" &&  onGoTo('prev');
  }
  
  return (
    <div className="shop-all">
      <div className="shop-header-out">
        <ShopHeader handleNav={handleNav} />
      </div>
      <div className="shop-item-out">
        <TinySlider settings={settings} ref={ts}>
          {
            shopItems.map((item, index) => (
              <div key={index} style={{position: 'relative'}}>
                <ShopItem item={item} handleBuy={handleBuy}/> 
              </div>
            ))
          }
        </TinySlider>
      </div>
      { 
        item.view === true && <OrderForm item = {item} handleCancel={handleCancel}/>
      } 
    </div>
  )
}

export default Shop
