import React from 'react'

import './logo.css'


function MenuBarLogo() {
  return (
    <div className="logo-inner">
      <a href="http://localhost">
        <img src="images/kk-blokovi-logo-cirilica-150x150.png" alt="KK Blokovi logo" />
      </a>
    </div>
  )
}

export default MenuBarLogo