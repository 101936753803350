import React, { useState, useEffect } from 'react'
import { infobardata } from './infobar.data'
import InfoBarText from './InfoBarText'
import './infobar.css'


function InfoBar() {
  const [curr, setCurr] = useState(0);
  const {length} = infobardata
  const myTimeout = setTimeout(NextLine, 6000);

  useEffect(() => {
    setMyTimeout()
  })

  function setMyTimeout() {
    return myTimeout
  }

  function clearMyTimeout() {
    clearTimeout(myTimeout)
  }

  function NextLine() {
    setCurr( curr === length - 1 ? 0 : curr + 1)
  }

  return (
    <div className="info-bar" >
      <p className="info-bar-title">VESTI: </p>
      <InfoBarText 
        print={infobardata[curr].text} 
        still={ curr % 2 === 0 ? 'info-bar-text1': 'info-bar-text2'}
        handleMouseOver={() => clearMyTimeout}
      />
    </div>
  )
}


export default InfoBar


