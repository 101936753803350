import React from 'react'
import Blokovi from './Blokovi'
import FooterNav from './FooterNav'
import Mails from './Mails'
import Links from './Links'

import Treners from './Treners'


import './footer.css'

function Footer() {
  return (
    <div className="footer container">
      <div className="footer-item">
        <Blokovi />
      </div>
      <div className="footer-item">
        <FooterNav />
      </div>
      <div className="footer-item">
        <Treners />
      </div>
      <div className="footer-item">
        <Links />
      </div>
      <div className="footer-item">
        <Mails /> 
      </div>
    </div>
  )
}

export default Footer


