import React from 'react'
import './shopheader.css'

function ShopHeader(props) {
  return (
    <div className="shop-header">
      <div className="shop-header-title">
        Prodavnica
      </div>
      <div className="shop-header-nav">
        <div className="shop-header-nav-left">
          <button 
            className="shop-nav-left"
            data-dir="-1"
            onClick={props.handleNav}
          >
            <span> &#8896;</span>
          </button>
        </div>
        <div className="shop-header-nav-right">
          <button
            className="shop-nav-right"
            data-dir="1"
            onClick={props.handleNav}
          >
            <span>&#8897;</span>
          </button>
        </div>
      </div>
    </div>
  )
}



export default ShopHeader




/* <button onClick={(e) => props.handleMovement(e)} data-tempo="-1">
  &#x3c;
</button>
<button onClick={(e) => props.handleMovement(e)} data-tempo="1" >
  &#x3e;
</button>
 */