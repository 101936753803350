export const clubheaderdata = [
  {
    id: 1,
    title: "ŠKOLA KOŠARKE",
    img: "images/club/club-1-bg.jpg",
    text: "Klub vode ljudi koji su odrastali na novobeogradskim košarkaškim terenima. " + 
          "Primarni cilj delovanja košarkaškog kluba BLOKOVI je usmeravanje novih " + 
          "generacija naše dece iz sveta ulice, pogrešnih uzora i malodušnosti u " + 
          "svet košarke, drugarstva i životnog optimizma. " + 
          "Klub je pre svega formiran za klince iz blokova koji su već generacijama " +
          "simbol odličnih basketaša... Mnogi od vrhunskih srpskih košarkaša svoje " + 
          "prve košarkaške korake pravili su na terenima u BLOKOVIMA. Osim " +
          "'blokovske' dece u klub su dobrodošli svi koji vole košarku i život " +
          "i rad u pravom sportskom ambijentu!"
  },
  {
    id: 2,
    title: "MISIJA KLUBA",
    img: "images/club/club-2.jpg",
    text: "Što duže decu zadržimo u sportskoj sali, " +
          "manja je verovatnoća da će posrnuti u životu. Pružamo deci ljubav, košarkaško " +
          "znanje i pažnju koja im je potrebna. " +
          "Treninzi u našem klubu su organizovani tako da pored košarkaških veština deci " +
          "'usađujemo' i osnovna pravila zdravog života, odnosa prema okolini, vaspitanje... " +
          "Učimo ih pravim vrednostima i tako pomažemo da postanu pravi ljudi! " + 
          "Svi treneri u KK BLOKOVI su bivši igrači, zaljubljenici u košarkašku igru, " + 
          "obučeni za rad sa mladima i veoma obrazovani."
  },
  {
    id: 3,
    title: "BRIGA O MLADIMA",
    img: "images/club/club-3-bg.jpg",
    text: "Najvažniji i najosetljiviji aspekt našeg rada je rad sa najmlađima. " +
          "Posebnu pažnju poklanjamo pravilnom telesnom i mentalnom razvoju dece. " +
          "Osim košarke, na prvom mestu je i pažnja koju posvećujemo obrazovanju " +
          "naših članova, kroz stalnu komunikaciju sa roditeljima, a uz saglasnost " +
          "roditelja, od članova zahtevamo listu ocena sa svakog tromesečja... " +
          "Na taj način želimo da postignemo uzajamnu povezanost košarke i obaveznog " +
          "obrazovanja! Da ljubav koju smo izgradili prema košarci „upotrebimo“ kao motiv " +
          "za ostvarivanje boljih rezultata u školi... " +    
          "Naš osnovni zadatak je da najmlađi zavole košarku, da uživaju u igri, " +
          "učenju, takmičenju, da ih na taj način što je moguće duže zadržimo u sportskoj " +
          "dvorani i na taj način sačuvamo od štetnog uticaja medija, ulice i td."
  },
  {
    id: 4,
    title: "ZLATNI SPONZOR",
    img: "images/club/henkel-bg.jpg",
    text: "Naš prepoznatljiv način rada sa najmlađima, omogućio nam je višegodišnju " +
          "saradnju sa kompanijom HENKEL koja nam kroz godišnje MIT  projekte " +
          "pruža podršku i obezbeđuje najkvalitetnije sportske rekvizite za rad."
  },
  {
    id: 5,
    title: "MARKETING",
    img: "images/club/marketing.jpg",
    text: "Ukoliko želite da informacija o Vašoj kompaniji ili proizvodima i uslugama " +
          "koje nudite dođe do velikog broja potrošača koji žive na teritoriji našeg kluba, " +
          "bilo kroz direktan marketing ili kroz društveno odgovorne aktivnosti, kontaktirajte " +
          "naš MARKETING TIM koji će znati da Vas posavetuje i pronađe najbolji i najlakši put za Vas " +
          "i Vaše proizvode... Sarađujući sa našim klubom, u isto vreme činite dobro Vašoj kompaniji kao i mladima " +
          "koji rastu u našem klubu... Kontaktirajte naš MARKETING TIM i saznajte zašto smo drugačiji od drugih!!!"
  },
  {
    id: 6,
    title: "TRENER",
    img: "images/footer/sale-bg.jpg",
    text: "Naš glavni trener je Aleksandar Timotijević,  diplomirani košarkaški trener (licenca br.4809) " +
          "nekadašnji kadetski reprezentativac Srbije. Njegovo dugogodišnje iskustvo u radu sa mlađim " +
          "kategorijama je naša najbolja preporuka. Izuzetan pedagog. Naoružan strpljenjem i pun ljubavi " +
          "za najmlađe. Nesebičan u prenosu znanja i iskustava, kako košarkaških tako i životnih. Veoma iskusan i u " +
          "kolektivnom i u individualnom radu sa igračima svih takmičarskih kategorija. Odrastao u blokovima. Otac troje dece."
  },
  {
    id: 7,
    title: "TRENER",
    img: "images/footer/crni-bg.jpg",
    text: "Aleksandar Đukelić, čovek neiscrpnog strpljenja i razumevanja za najmlađe. Obrazovan trener. Beograđanin, otac dvoje dece."
          
  },
  {
    id: 8,
    title: "UPRAVA",
    img: "images/club/uprava-bg.jpg",
    text: "25.05.2016. " +
          "Danas smo i formalno oživeli jednu čistu i iskrenu ideju. Održali smo " +
          "osnivačku skupštinu KK BLOKOVI. " +
          "U sjajnom ambijentu kod naših prijatelja TEATAR CARTE BLANCHE i u " +
          "svečanom raspoloženju doneli smo odluku o početku rada našeg kluba."
  }
]

    