import React from 'react'
import TopBar from './topbar/TopBar'
import Nav from './nav/Nav'
import Logo from './logo/Logo'

import './header.css';

function Header() {
  return (
    <div className="header-wrap">
      <TopBar />
      <header className="header">
        <div className="header-container">
          <div className="header-bg"></div>
          <div className="logo">
            <Logo />
          </div> 
          <div className="nav">
            <Nav />
          </div>
        </div>
      </header>
    </div>
  )
}


export default Header
