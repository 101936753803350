import React from 'react'
import './clubheader.css'

function ClubHeader({item, handleClick, current}) {
  let active = ((current === item.id) ? 'active-menu-item' : "")
  return (
    <div className={`club-up-left-menu-item ${active}`} data-menu={item.id} key={item.id} onClick={(e) => handleClick(e)}>
      {item.title}
    </div>
  )
}

export default ClubHeader


