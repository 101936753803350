import React from 'react'
import InfoBar from './InfoBar'
import TopBarMail from './TopBarMail'
import TopBarPhone from './TopBarPhone'
import TopBarSocial from './TopBarSocial';

import './topbar.css'


function TopBar() {
  return (
    <div className="top-bar">
      <div className="infobar">
        <InfoBar />
      </div>
      <div className="top-bar-right">
        <TopBarMail />
        <div className="top-bar-right-delimiter">&nbsp;/</div>
        <TopBarPhone />
        <div className="top-bar-right-delimiter">&nbsp;/</div>
        <TopBarSocial />
      </div>
    </div>
  )
}

export default TopBar




