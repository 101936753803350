import React from 'react';

//import Slider from './components/slider/Slider'
import Slidervideo from './components/video/Slidervideo'
import Header from './components/header/Header'
//import Leagues from './components/leagues/Leagues'
//import Teams from './components/teams/Teams'

import Sponsors from './components/sponsors/Sponsors'
import Club from './components/club/Club'
import Media from './components/media/Media'
import Friends from './components/friends/Friends'
import Shop from './components/shop/Shop'
//import Instagram from './components/instagram/Instagram'
//import Orasac from './components/orasac/Orasac'
import Footer from './components/footer/Footer'
import News from './components/news/news'

//import BigMenu from './components/bigmenu/BigMenu'
//import Players from './components/players/Players'
//import PionirTeam from './components/pionir-team/PionirTeam'

import './App.css';

function App() {
  return (
    <div className="App">
      <div className="header-wrapper">
        <Header />
      </div>

      <div className="slider-wrapper">
        <Slidervideo />
        <div className="sliderbutton">
          <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/dir//Pro+Sport+Sportska+Hala/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x475a6f389c8117e3:0x7fecd88da3dfb9a7?sa=X&ved=2ahUKEwiZu635t7XsAhWTTxUIHR1BB6IQ9RcwFHoECA8QAw">
            <span >HALA "PRO SPORT"</span>
            <span>LEDINE</span>
          </a>
        </div>
      </div>

      <div className="news-wrapper">
        <News />        
      </div>

      {/* <div className="league-wrapper">
        <Leagues />
      </div> */}

      {/* <div className="teams-wrapper">
        <Teams />
      </div> */}

      <div className="sponsors-wrapper">
        <Sponsors />
      </div>

      <div className="club-wrapper">
        <Club />
      </div>

      <div className="media-wrapper">
        <div className="media-wrapper-bg"></div>
        <div className="media-wrapper-mask"></div>
        <Media />
      </div>

      <div className="friends-wrapper">
        <Friends />
      </div>

      <div className="shop-wrapper">
        <Shop />
      </div>

      {/* <div className="instagram-wrapper">
        <Instagram />
      </div> */}

     {/*  <div className="orasac-wrapper">
        <Orasac />
      </div> */}

       <div className="footer-wrapper">
        <Footer />
      </div> 
      
      {/* <div className="marker"></div> */}
    </div>
  )
}

export default App;
