import React, {useState} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2'
import {useSpring, animated} from 'react-spring'

import './orderform.css'

function OrderForm({item, handleCancel}) {
  const [error, setError] = useState({
    name: "",
    adress: "",
    phone: "",
    size: "",
    amount: "",
    payment: ""
  })

  const props = useSpring({opacity:1, from: {opacity:0}})

  const [name, setName] = useState("")
  const [adress, setAdress] = useState("")
  const [phone, setPhone] = useState("")

  const handleChange = e => {
    

    if (e.target.name === "name" ) {
      if ( /[0-9]/.test(e.target.value)) {
        setError({name :"Dozvoljena su samo slova!"})
      } else {
        setName(e.target.value)
        setError({name: ""})
      }
    }

    if  (e.target.name === "adress") {
      setAdress(e.target.value)
    }

    if (e.target.name === "phone" ) {
      if (  /[^0-9]/.test(e.target.value)) {
        setError({ phone: "Dozvoljeni su samo brojevi!"})
      } else {
        setPhone(e.target.value)
        setError({ phone: ""})
      }
    }
  }

  const handleSubmit = e => {
      //console.log(e.target)
    if (e.target[3].value === "") {
      setError({name : "Ime i prezime je obavezan podatak!"})
    } else if (e.target[4].value === "") {
      setError({adress : "Adresa je obavezan podatak!"})
    } else if (e.target[5].value === "") {
      setError({phone : "Telefon je obavezan podatak!"})
    } else if (e.target[6].value === "") {
      setError({size : "Veličina je obavezan podatak!"})
    } else if (e.target[7].value === "") {
      setError({amount : "Količina je obavezan podatak!"})
    } else if (e.target[8].value === "") {
      setError({payment : "Način plaćanja je obavezan podatak!"})
    } else {
      const data = {
        title: e.target[0].value,
        art: e.target[1].value,
        img: e.target[2].value,
        name: e.target[3].value,
        adress: e.target[4].value,
        phone: e.target[5].value,
        size: e.target[6].value,
        amount: e.target[7].value,
        payment: e.target[8].value
      }
      sendData(data)
      Swal.fire({
        title: 'VAŠ ZAHTEV JE PROSLEĐEN! Hvala što koristite online prodavnicu',
        text: 'Kontaktiraćemo VAS u vezi isporuke!',
        imageUrl: 'kk-blokovi-logo-cirilica-128x128.png',
        imageWidth: 128,
        imageHeight: 128,
        imageAlt: 'KK BLOKOVI BEOGRAD',
      })
      handleCancel()
    }

    e.preventDefault()
  }

  async function sendData(podaci) {
    //console.log(podaci)
    //const zaslanje = JSON.stringify(podaci)
    //console.log(zaslanje)
    await Axios.post( "https://www.kkblokovi.rs/glavna.php", {
      //data: {podaci: zaslanje}
      title: podaci.title,
      art: podaci.art,
      img: podaci.img,
      name: podaci.name,
      adress: podaci.adress,
      phone: podaci.phone,
      size: podaci.size,
      amount: podaci.amount,
      payment: podaci.payment
    })
      .then(function(response) {
        console.log(response)
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  
  return (
    
    <animated.div style={props} className="order-window">
     
      <div className="order-modal">
       
        <div className="order-article">
          <div className="order-article-title">
            <p>{item.title}</p>
            <p>{item.art}</p>
          </div>
          <img src={item.img} alt="KK BLOKOVI Shop"/>
        </div>
        
        <div className="order-title">
          <p>Molimo, popunite formu za poruǆbinu. Svi podaci su obavezni!</p>
        </div>
        <form action="" method="POST" className="order-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <input type="hidden" name="title" value={item.title} />
          <input type="hidden" name="art" value={item.art} />
          <input type="hidden" name="img" value={item.img} />
          <div className="order-fieldset1">
            <div className="order-form-group">
              <input 
                type="text" 
                className="order-form-control" 
                name="name" 
                id="name" 
                value={name}  
                required 
                onChange={handleChange}
              />
              <label htmlFor="name">Ime i prezime:</label>
              <span>{error.name}</span>
            </div>
            <div className="order-form-group">
              <input 
                type="text" 
                className="order-form-control" 
                name="adress" 
                id="adress" 
                value={adress} 
                required 
                onChange={handleChange}
              />
              <label htmlFor="adress">Adresa:</label>
              <span>{error.adress}</span>
            </div>
            <div className="order-form-group">
              <input 
                type="text" 
                className="order-form-control" 
                name="phone" 
                id="phone" 
                value={phone} 
                required 
                onChange={handleChange}
              />
              <label htmlFor="phone">Telefon:</label>
              <span>{error.phone}</span>
            </div> 
          </div>

          <div className="order-title">
            <p>Odaberite veličinu i količinu </p>
          </div> 

          <div className="order-fieldset2">
            <div className="order-form-group">
              <label>Veličina: </label>
              <select  className="order-form-control" name="size" id="size">
                <option value=""></option>
                <option value="12">12</option>
                <option value="14">14</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
                <option value="XXXL">XXXL</option>
                <option value="Nema veličinu">Nema veličinu</option>
              </select>
              <span>{error.size}</span>
            </div>
            <div className="order-form-group">
              <label>Količina: </label>
              <select className="order-form-control" name="amount" id="amount">
                <option value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <span>{error.amount}</span>
            </div>  
          </div>

          <div className="order-title">
            <p>Izaberite način plaćanja i isporuke</p>
          </div>
          
          <div className="order-fieldset3">
            <div className="order-form-group">
              <select className="order-form-control" name="payment">
                <option value=""></option>
                <option value="Postexpres">Pouzećem - Brza pošta</option>
                <option value="Licno">Lično preuzimanje u klubu</option>
              </select> 
              <span>{error.payment}</span>
            </div>
          </div>

          <div className="order-form-buttons">
            <button 
              className="order-close-button" 
              onClick={handleCancel}
            >
              NAZAD
            </button>
            <button
              type="submit"
              className="order-submit-button"
            >
              PORUČI
            </button>
          </div>
          
        </form>
      </div>
    </animated.div>
  )

}

export default OrderForm

