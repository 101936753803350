import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

function TopBarPhone() {
  return (
    <div className="top-bar-phone">
      <FontAwesomeIcon icon={faPhoneAlt} />
      <span>065 5 213 321</span>
    </div>
  )
}

export default TopBarPhone