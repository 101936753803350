import React, { useState } from 'react'
import animateScrollTo from 'animated-scroll-to';
import MenuBg from './MenuBg'
import './nav.css'
import { menudata } from './menudata'

function Nav() {
  const [left, setLeft ] = useState(20)
  const [width, setWidth ] = useState(70)

  function handleMouseOver(e) {
    e.preventDefault()
    setLeft(e.target.offsetLeft)
    setWidth(e.target.offsetWidth)
  }

  function handleMouseLeave() {
    setLeft(20)
    setWidth(70)
  }

  function navClick(e) {
    animateScrollTo(document.querySelector(e.target.dataset.direction));
  }

  return (
    <div className="main-menu">
      <MenuBg left={left} width={width}/>
      <ul 
        className="main-menu-nav"
        onMouseLeave={() => handleMouseLeave()}
      >
        {
          menudata.map( item => (
            <li
              onMouseOver={(e) => handleMouseOver(e)}
              className="main-menu-item"
              key={ item.id }
              onClick = {e => navClick(e)}
              data-direction={item.link}
            >
              { item.title }
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Nav

