import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen  } from '@fortawesome/free-regular-svg-icons'

function TopBarMail() {
  return (
    <div className="top-bar-mail">
      <FontAwesomeIcon  icon={faEnvelopeOpen} />
      <span>office@kkblokovi.rs</span>
    </div>
  )
}

export default TopBarMail