import React from 'react'

import './blokovi.css'

function Blokovi() {
  return (
    <div className="blokovi">
      <div className="blokovi-title">
        KK BLOKOVI
      </div>
      <div className="blokovi-content">
        <div className="blokovi-bg">
          <img src="images/footer/blokovi-footer.png" alt="KK BLOKOVI"/>
        </div>
        <div className="blokovi-subtitle">
          <div>NIKAD PORAZ</div>
          <div>UVEK LEKCIJA</div>
        </div>
      </div>
    </div>
  )
}

export default Blokovi


