import React, {useState, useEffect} from 'react'

import Friend from './Friend'

import {friendsData} from './friendsData'
import './friends.css'

function Friends() {
  let [friends, setFriends] = useState([])
  
  useEffect(() =>  {
    setFriends(friendsData)
  },[])

  return (
    <div className="friends-out container">
      {
        friends.map((item,idx) => (
          <Friend key={idx} item={item} />
        ))
      }
    </div>
  )
}

export default Friends


