import React, {useEffect} from 'react'
import anime from 'animejs'

import './mediatitle.css'

function MediaTitle({id,title,text, direction}) {
  const bigTitle = title.split('');

  useEffect(() => {
    direction < 0 ? animation_down() : animation_up()
    return () => {animation_down()}
  }, [direction])

  const animation_down = (() => anime({
    targets: ['span.one-letter', '.media-title-text'],
    translateY: [
      { value: 100, duration: 500 },
      { value: 0, duration: 1000 },
    ],
    opacity: [
      { value: 0, duration: 500},
      { value: 1, duration: 1000},
    ],
   
    delay: function(el, i, l){ return i * 10},
  }))

  const animation_up = (() => anime({
    targets: ['span.one-letter', '.media-title-text'],
    translateY: [
      { value: -100, duration: 500 },
      { value: 0, duration: 1000 },
    ],
    opacity: [
      { value: 0, duration: 500},
      { value: 1, duration: 1000},
    ],
    
    delay: function(el, i, l){ return i * 30},
  }))



  return (
    <div className="media-title">
      
      <div className="media-title-title">
        {
          bigTitle.map((item, idx) => (
            <span key={idx} className="one-letter">{item}</span>
          ))
        }
      </div>
      <div className="media-title-text">
        {text}
      </div>
    </div>
  )
}

export default MediaTitle
