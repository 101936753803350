import React,{useState} from 'react'

import { newsData } from './actualnoData'
import './news.css'

function News() {
  //const [showActual, setShowActual] = useState(true)
  const showActual = true
  const [imgSrc, setImgSrc] = useState('')

  return (
    <div className="news container">
      <div className="news-button">
        {/* <button onClick={() => setShowActual(!showActual)}>{ !showActual ? 'AKTUELNO': 'ZATVORI'}</button> */}
        <div className="button-line">
          
        </div>
      </div>
      <div className='news-title'>
        <h1>Treće mesto u regionalnoj ligi KSB za sezonu 2021/22</h1>
      </div>
      <div className="news-content">
        {
          showActual && (
            
            newsData.map(news => (  
              <div className="news-body" key={news.id}>    
                <div className="news-body-text">
                  <h1>{news.title}</h1>
                  <p>{news.description}</p>
                </div>
                <div className="news-body-img">
                  {
                    news.media === "img"
                    ? <img 
                        src={news.img} alt=""
                        onClick={(e) => setImgSrc(e.currentTarget.src)}
                      />
                    : <video controls>
                        <source src={news.img} type="video/mp4"></source>
                      </video>
                  }     
                </div> 
              </div>
            ))
          )
        }
         
      </div>
      {
          imgSrc !== '' && (
            <div className="modal-img">
              <img 
                src={imgSrc} 
                alt="KK BLokovi" 
                onClick={() => setImgSrc('')}
              />
              <span onClick={() => setImgSrc('')}>X</span>
            </div>
          )
        } 
    </div>
  )
}


export default News