export const shopItems = [
  {
    id: 1,
    tag: 'Šorc',
    art: 'PGES-1',
    title: 'Muški sportski šorc',
    img: "images/shop/pges-1.jpg",
    price: "2.500,00"
  },
  {
    id: 2,
    tag: 'Šorc',
    art: 'PGES-2',
    title: 'Muški sportski šorc',
    img: "images/shop/pges-2.jpg",
    price: "2.500,00"
  },
  {
    id: 3,
    tag: 'Šorc',
    art: 'PGES-3',
    title: 'Muški sportski šorc',
    img: "images/shop/pges-3.jpg",
    price: "2.500,00"
  },
  {
    id: 4,
    tag: 'Šorc',
    art: 'PGES-4',
    title: 'Muški sportski šorc',
    img: "images/shop/pges-4.jpg",
    price: "2.500,00"
  },
  {
    id: 5,
    tag: 'Duks',
    art: 'DK-W01',
    title: 'Duks hoody sa kapuljačom',
    img: "images/shop/duks-hoody-DK-W01.jpg",
    price: "3.950,00"
  },
  {
    id: 6,
    tag: 'Ranac',
    art: 'R2021',
    title: 'Sportski ranac',
    img: "images/shop/ranac-r2021.jpg",
    price: "3.400,00"
  },
  {
    id: 7,
    tag: 'Majica',
    art: 'MPES-1',
    title: 'Majica kratki rukav',
    img: "images/shop/mpes-1.jpg",
    price: "1.850,00"
  },
  {
    id: 8,
    tag: 'Majica',
    art: 'MPES-2',
    title: 'Majica kratki rukav',
    img: "images/shop/mpes-2.jpg",
    price: "1.850,00"
  },
  {
    id: 9,
    tag: 'Majica',
    art: 'MPES-3',
    title: 'Majica kratki rukav',
    img: "images/shop/mpes-3.jpg",
    price: "1.850,00"
  },
  {
    id: 10,
    tag: 'Majica',
    art: 'MPES-4',
    title: 'Majica kratki rukav',
    img: "images/shop/mpes-4.jpg",
    price: "1.850,00"
  },




  {
    id: 11,
    tag: 'Duks komplet',
    art: 'TC-K2',
    title: 'Crveni hoody komplet',
    img: "images/shop/hoody-complet-tck2.jpg",
    price: "6.500,00"
  },
  {
    id: 12,
    tag: 'Duks komplet',
    art: 'TZ-K4',
    title: 'Žuti hoody komplet',
    img: "images/shop/hoody-complet-tzk4.jpg",
    price: "6.500,00"
  },
  {
    id: 13,
    tag: 'Duks komplet',
    art: 'DRS-K1',
    title: 'Sivi duks komplet',
    img: "images/shop/hoody-complet-drsk1.jpg",
    price: "6.900,00"
  },
  {
    id: 14,
    tag: 'Duks komplet',
    art: 'DRT-K2',
    title: 'Teget duks komplet',
    img: "images/shop/hoody-complet-drtk2.jpg",
    price: "6.900,00"
  },
  {
    id: 15,
    tag: 'Duks',
    art: 'TZ-G1',
    title: 'Duks hoody sa kapuljačom',
    img: "images/shop/duks-hoody-tzg1.jpg",
    price: "3.800,00"
  },
  {
    id: 16,
    tag: 'Duks',
    art: 'TC-G2',
    title: 'Duks hoody sa kapuljačom',
    img: "images/shop/duks-hoody-tcg2.jpg",
    price: "3.800,00"
  },
  {
    id: 17,
    tag: 'Duks',
    art: 'TZ-G3',
    title: 'Duks hoody sa kapuljačom',
    img: "images/shop/duks-hoody-tzg3.jpg",
    price: "3.800,00"
  },
  {
    id: 18,
    tag: 'Duks',
    art: 'TZ-G4',
    title: 'Duks hoody sa kapuljačom',
    img: "images/shop/duks-hoody-tzg4.jpg",
    price: "3.800,00"
  },
  {
    id: 19,
    tag: 'Dres',
    art: 'DR-B01',
    title: 'Dres - beli sezona 2019/20',
    img: "images/shop/dres-beli.jpg",
    price: "2.900,00"
  },
  {
    id: 20,
    tag: 'Dres',
    art: 'DR-P01',
    title: 'Dres - plavi sezona 2019/20',
    img: "images/shop/dres-teget.jpg",
    price: "2.900,00"
  },
  {
    id: 21,
    tag: 'Dres',
    art: 'DR-B02',
    title: 'Dres - beli sezona 2018/2019',
    img: "images/shop/dres-stari.jpg",
    price: "2.900,00"
  },
  {
    id: 22,
    tag: 'Duks',
    art: 'DK-B01',
    title: 'Duks svetli sa kapuljačom',
    img: "images/shop/duks-sivi-01.jpg",
    price: "3.950,00"
  },
  {
    id: 23,
    tag: 'Duks',
    art: 'DK-S02',
    title: 'Duks sivi sa kapuljačom',
    img: "images/shop/duks-sivi-02.jpg",
    price: "3.950,00"
  },
  {
    id: 24,
    tag: 'Duks',
    art: 'DK-T03',
    title: 'Duks teget sa kapuljačom',
    img: "images/shop/duks-teget-01.jpg",
    price: "3.950,00"
  },
  {
    id: 25,
    tag: 'Duks',
    art: 'DRS-1',
    title: 'Duks sa rajferšlusom',
    img: "images/shop/duks-rajf-drs1.jpg",
    price: "3.950,00"
  },
  {
    id: 26,
    tag: 'Duks',
    art: 'DRT-2',
    title: 'Duks sa rajferšlusom',
    img: "images/shop/duks-rajf-drt2.jpg",
    price: "3.950,00"
  },
  {
    id: 27,
    tag: 'Duks',
    art: 'DRS-3',
    title: 'Duks sa rajferšlusom',
    img: "images/shop/duks-rajf-drs3.jpg",
    price: "3.950,00"
  },
  {
    id: 28,
    tag: 'Activewear',
    art: 'AW-G01',
    title: 'Akrivni veš sa grbom - gornji',
    img: "images/shop/activewear-gornji.jpg",
    price: "1.200,00"
  },
  {
    id: 29,
    tag: 'Activewear',
    art: 'AW-D01',
    title: 'Akrivni veš sa grbom - donji',
    img: "images/shop/activewear-donji.jpg",
    price: "1.400,00"
  },
  {
    id: 30,
    tag: 'Majica',
    art: 'MO-B01',
    title: 'Majica bela - kratki rukav',
    img: "images/shop/majica-bela-01.jpg",
    price: "- - - ,- -"
  },
  {
    id: 31,
    tag: 'Majica',
    art: 'MO-B02',
    title: 'Majica bela - kratki rukav',
    img: "images/shop/majica-bela-02.jpg",
    price: "- - - , - -"
  },
  {
    id: 32,
    tag: 'Majica',
    art: 'MO-B03',
    title: 'Majica bela - kratki rukav',
    img: "images/shop/majica-bela-03.jpg",
    price: "- - - , - -"
  },
  {
    id: 33,
    tag: 'Majica',
    art: 'MP-01',
    title: 'Majica polo - kratki rukav',
    img: "images/shop/majica-bela-04.jpg",
    price: "- - - , - -"
  },
  {
    id: 34,
    tag: 'Majica',
    art: 'M0-B04',
    title: 'Majica bela - kratki rukav',
    img: "images/shop/majica-bela-05.jpg",
    price: "- - - , - -"
  },
  {
    id: 35,
    tag: 'Majica',
    art: "'M0-T01",
    title: "Majica teget - kratki rukav",
    img: "images/shop/majica-teget-01.jpg",
    price: "- - - , - -"
  },
  {
    id: 36,
    tag: 'Majica',
    art: 'M009',
    title: 'Majca plava - kratki rukav',
    img: "images/shop/majica-teget-02.jpg",
    price: "- - - , - -"
  }
]