export const menudata = [
  {
    id: 1,
    title: 'Početna',
    level: '1',
    parentlevel: '0',
    link: '.header-wrapper'
  },
  {
    id: 2,
    title: 'Liga',
    level: '1',
    parentlevel: '0',
    link: '.league-wrapper'
  },
  {
    id: 3,
    title: 'Ekipe',
    level: '1',
    parentlevel: '0',
    link: '.teams-wrapper'
  },
  {
    id: 4,
    title: 'Klub',
    level: '1',
    parentlevel: '0',
    link: '.club-wrapper'
  },
  {
    id: 5,
    title: 'Arhiva',
    level: '1',
    parentlevel: '0',
    link: '.media-wrapper'
  },
  {
    id: 6,
    title: 'Prodavnica',
    level: '1',
    parentlevel: '0',
    link: '.shop-wrapper'
  },
  {
    id: 7,
    title: 'Kontakt',
    level: '1',
    parentlevel: '0',
    link: '.footer-wrapper'
  }
]