export const sponsorData =[
  {
    id: 1,
    title: "Atp Sport",
    img: "images/sponsor/atp.png",
    url: "http://www.atpsport.com/"
  },
  {
    id: 2,
    title: "Henkel",
    img: "images/sponsor/henkel.svg",
    url: "https://www.henkel.rs/"
  },
  {
    id: 3,
    title: "ENP Systems",
    img: "images/sponsor/enpsystems.jpg",
    url: "https://www.kkblokovi.rs/"
  },
  {
    id: 4,
    title: "Toyota",
    img: "images/sponsor/toyota.png",
    url: "http://www.magcentar.rs/"
  },
  {
    id: 5,
    title: "Teatar Carte Blanche",
    img: "images/sponsor/carteblanche.png",
    url: "https://www.facebook.com/carte.blanche.beograd/"
  },
  {
    id: 6,
    title: "GasTeh",
    img: "images/sponsor/gasteh.jpg",
    url: "https://gasteh.com/"
  }
]
