import React, {useState} from 'react'
import ClubHeader from './ClubHeader'
import ClubBodyImage from './ClubBodyImage'
import ClubBodyText from './ClubBodyText'

import {clubheaderdata} from './clubdata'
import './club.css'


function Club() {
  const[current, setCurrent] = useState(1)

  const handleClick = ((e) => {
    setCurrent(Number(e.target.dataset.menu))
  })
  
  return (
    <div className="club container" >

      <div className="club-up" >

        <div className="club-up-left">

          <div className="club-up-left-logo">
            <div className="club-up-left-logo-image">  
              <img src="images/kk-blokovi-logo-cirilica-150x150.png" alt="KK BLOKOVI" />
            </div>

            <div className="club-up-left-logo-text">
              <h2>Košarkaški klub</h2>
              <h2>BLOKOVI</h2>
              <h2>Novi Beograd</h2>
            </div>
          </div>

          <div className="club-up-left-menu">
            {
              clubheaderdata.map(item => (
                <ClubHeader key={item.id} item={item} handleClick={handleClick} current={current}/>
              ))
            }
          </div>
        </div>

        <div className="club-up-right">
          <div className="club-up-right-box">
            {
              clubheaderdata.map(item => (
                <ClubBodyImage key={item.id} item={item} current={current}/>
              ))
            }
          </div>
          
        </div>

      </div> 

      <div className="club-down">
        <div className="club-down-text">
        {
          clubheaderdata.map(item => (
            <ClubBodyText key={item.id} item={item} current={current}/>
          ))
        }
        </div>
        
      </div>
    </div>
  )
}


export default Club