import React from 'react'

import './shopitem.css'

function ShopItem({item, handleBuy}) {


  return (
    <div className="shop-item" >
      <div className="shop-item-img">
        <img src={item.img} alt={`KK Blokovi ${item.name}`} />
      </div>
      <div className="shop-item-text"> 
        <div className="shop-item-text-tag">
          <div className="shop-item-text-tag-left">
            {item.tag}
          </div>
          <div className="shop-item-text-tag-right">
            {item.art}
          </div>
        </div>
        <div className="shop-item-text-title">
          {item.title}
        </div>
        <hr />
      </div>
      <div className="shop-item-price">
        <button 
          className="shop-item-order" 
          data-img={item.img} 
          data-art={item.art} 
          data-title={item.title} 
          onClick={handleBuy}
        >
          PORUČI
        </button> 
        <p className="shop-item-price-value">{item.price}</p>
      </div> 
    </div>
  )
}

export default ShopItem


