export  const infobardata = [
  {
    id: 1,
    text: 'NOVA OPREMA U PONUDI! Pogledati u "PRODAVNICA"'
  },
  {
    id: 2,
    text: "KAMP 2021 - MATARUŠKA BANJA - POGLEDATI U 'ARHIVA'"
  },
  {
    id: 3,
    text: 'KAMP - JASTREBAC - POGLEDATI U "AРHIVA"'
  },
  {
    id: 4,
    text: "SLIKE SA KAMPA POGLEDATI U AKTUELNO"
  },
  {
    id: 5,
    text: "Od 23. avgusta do 29. avgusta. Vila IDILA Jastrebac"
  },
  {
    id: 6,
    text: "AKTUELNO - SLIKE I VIDEO SA JASTREBCA !!!"
  }
]