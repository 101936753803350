import React, { useRef } from 'react'
import TinySlider from 'tiny-slider-react'

import './mediacontent.css'

function MediaContent({images, current}) {
  const ts = useRef(null)
  function handleNav(e) {
    e.currentTarget.dataset.dir === "1" ? onGoTo('prev') : onGoTo('next')
  }

  const settings = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    controls: false,
    items: 1,
    center: true,
    autoplayButtonOutput: false,
    speed: 700,
    slideBy: 1,
    loop: false,

  }

  const onGoTo = dir => ts.current.slider.goTo(dir)

  return (
    <div className="media-content">
      <TinySlider settings={settings} ref={ts}>
        {
          images.map((img, idx) => {
            return (
              <div className="media-content-img" key={idx}> 
                {img.media === "img" 
                  ? <img src={img.img} alt="Blokovi" />     
                  :  ( 
                        <video controls>
                          <source src={img.img} type="video/mp4"></source>
                        </video>
                      )
                }
              </div>
            )
          })
        }
      </TinySlider>
      <div className="media-nav">
        <button data-dir="1" onClick={handleNav}><span>&#8896;</span></button>
        <button data-dir="-1" onClick={handleNav}><span>&#8897;</span></button>
      </div>
    </div>
  )
}

export default MediaContent

