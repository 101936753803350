export const newsData = [
  {
    id: 4480,
    title: '',
    description: 'Ekipa mlađih pionira 2009/10 - KK Blokovi',
    media: 'img',
    img: "images/kkblokovi-3mesto-1.jpg"
  },
  {
    id: 4481,
    title: '',
    description: 'Ekipa mlađih pionira 2009/10 - KK Blokovi',
    media: 'img',
    img: "images/kkblokovi-3mesto-2.jpg"
  },
]
