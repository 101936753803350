import React, {useState, useEffect} from 'react'

import Sponsor from './Sponsor'

import { sponsorData } from './sponsorData';
import './sponsors.css'

function Sponsors() {
  let [sponsors, setSponsors] = useState([])
  
  useEffect(() =>  {
    setSponsors(sponsorData)
  },[])

  return (
    <div className="sponsors-out container">
      <div className="sponsors-bg">
      <p>S</p>
      <p>P</p>
      <p>O</p>
      <p>N</p>
      <p>Z</p>
      <p>O</p>
      <p>R</p>
      <p>I</p>
      </div>
      {
        sponsors.map((item, i) => (         
          <Sponsor key={i} item={item} />
        ))
      }
    </div>
  )
}

export default Sponsors
