import React from 'react'
//import Slide from './Slide'

import './slidervideo.css'

//import {slidesData} from './slidesdata'

function Slidervideo() {

  return (
    <div className="slider">
      <img src="kk-blokovi-logo-cirilica-512x512.png" alt="KK BLOKOVI" />
      <video loop muted autoPlay >
        <source src="kkblokovi.m4v" type="video/mp4" />
      </video>
    </div>
  )
}

export default Slidervideo

