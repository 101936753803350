import React from 'react'
import './infobartext.css'

function InfoBarText({print,still, handleMouseOver}) {
 
  return (
     <p className={`info-bar-text ${still}`} onMouseOver={handleMouseOver}>{print}</p>
  )
}

export default InfoBarText