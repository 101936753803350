import React from 'react'

function MenuBg(props) {
  return (
    <div 
      className="menu-bg"
      style={{
        left: `${props.left - 8}px`
      }}
    >
      <div 
        className="menu-bg-bar"
        style={{
          width: `${props.width + 16}px`
        }}
      ></div>
    </div>
  )
}

export default MenuBg