import React, { useState, useRef } from 'react'

import MediaTitle from './MediaTitle'
import MediaContent from './MediaContent'
import TinySlider from 'tiny-slider-react'
import {mediadata} from './mediadata'

import './media.css'

function Media() {
  const [direction, setDirection] = useState(1)
  const ts = useRef(null)
  const settings = {
    axis: "vertical", lazyload: true, nav: false, controls: false, items: 1,
    center: true, autoplayButtonOutput: false, speed: 1200, slideBy: 1, loop: false
  }

  const onGoTo = dir => ts.current.slider.goTo(dir)

  function handleNav(e) {
    e.currentTarget.dataset.dir === "1" ? onGoTo('prev') : onGoTo('next')
    setDirection(e.currentTarget.dataset.dir)
  }

  return (
    <div className="media">
      <div className="media-inner">  
        <TinySlider settings={settings} ref={ts}>
          {
            mediadata.map((dataitem, id) => (
              <div key={id} className="media-inner-item">
                <MediaTitle 
                  id={dataitem.id}
                  title={dataitem.title}
                  text={dataitem.text}
                  direction={ direction }
                />  
                <MediaContent 
                  images={dataitem.items}
                />  
              </div>  
            ))
          }
        </TinySlider>
        <div className="media-nav">
          <button data-dir="1" onClick={handleNav}><span>&#8896;</span></button>
          <button data-dir="-1" onClick={handleNav}><span>&#8897;</span></button>
        </div>
      </div>
     
    </div>
  )
}

export default Media
