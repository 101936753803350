import React from 'react'

import './treners.css'

function Treners() {
  return (
    <div className="treners">
      <div className="treners-title">
        TRENERI
      </div>
      <div className="treners-item">
        <div className="treners-img">
          <img src="images/footer/sale-bg.jpg" alt="Aleksandar Timotijević"/>
        </div>
        <div className="treners-data">
          <div className="treners-name">Aleksandar Timotijević</div>
          <div className="treners-mail">aleksandar@kkblokovi.rs</div>
          <div className="treners-phone">065 5 213 321</div>
        </div>
      </div>

      <div className="treners-item">
        <div className="treners-img">
          <img src="images/footer/crni-bg.jpg" alt="Aleksa Đukelic"/>
        </div>
        <div className="treners-data">
          <div className="treners-name">Aleksandar Đukelić - Crni</div>
          <div className="treners-mail">crni@kkblokovi.rs</div>
          <div className="treners-phone">060 161 33 30</div>
        </div>
      </div>

    </div>
  )
}

export default Treners


