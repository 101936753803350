import React from 'react'
import './clubbodytext.css'

function ClubBodyText({item, current}) {
  let active = ((current === item.id) ? 'active-text' : "")
  return (
    <div className={`club-down-text-item ${active}`}>
        <p>{item.text}</p>
    </div>
  )
}

export default ClubBodyText
