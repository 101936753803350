import React from 'react'
import animateScrollTo from 'animated-scroll-to'

import { menudata} from '../header/nav/menudata'
 
import './footernav.css'

function FooterNav() {

  function navClick(e) {
    animateScrollTo(document.querySelector(e.target.dataset.direction));
  }

  return (
    <div className="footer-nav">
      <div className="footer-nav-title">NAVIGACIJA</div>
      <div className="footer-nav-links">
        {
          menudata.map( item => (
            <div 
              className="footer-nav-links-item" 
              key={item.id} 
              data-direction={item.link}
              onClick =  {e => navClick(e)}
            >
              {item.title}
            </div>    
          ))
        }
      </div>
    </div>
  )
}

export default FooterNav


