import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function TopBarSocial() {
  return (
    <div className="top-bar-social">
      <a href="https://www.instagram.com/kkblokovi/?igshid=110m23cl7cjwv" target="_blanc">
        <FontAwesomeIcon className="infobarcontact-envelope" icon={faInstagram} />
      </a>
    </div>
  )
}

export default TopBarSocial