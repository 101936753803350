export const friendsData =[
  {
    id: 1,
    title: "Architectum",
    img: "images/friends/arhitectum.png",
    url: "http://www.architectum.rs/"
  },
  {
    id: 2,
    title: "Makler",
    img: "images/friends/makler.png",
    url: "https://www.makler.rs/"
  },
  {
    id: 3,
    title: "Print Pozitiv",
    img: "images/friends/pozitiv.jpg",
    url: "http://"
  },
  {
    id: 4,
    title: "Master Audio",
    img: "images/friends/masteraudio.jpg",
    url: "http://"
  },
  {
    id: 5,
    title: "Marko Curic",
    img: "images/friends/curic-marko.jpg",
    url: "http://"
  }
]
