import React from'react'

import './friend.css'

function Friend(props) {
  const {item} = props

  return (
    <div key={item.id} className="friend-item">
      <a href={item.url}>
        <img src={ item.img } alt={item.title} />
      </a>
    </div>
  )
}

export default Friend


