import React from 'react'

import './links.css'

function Links() {
  return (
    
    <div className="footer-links">
      <div className="footer-links-title">KORISNI LINKOVI</div>
      <div className="footer-links-links">
        <div className="footer-links-link">
          <a href="http://www.kss.rs/" target="_blank" rel="noopener noreferrer" >Košarkaški savez Srbije</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.ksb.rs/" target="_blank" rel="noopener noreferrer" >Košarkaški savez Beograda</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.euroleague.net/" target="_blank" rel="noopener noreferrer" >Evroliga</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.eurocupbasketball.com/" target="_blank"  rel="noopener noreferrer">Evrokup</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.aba-liga.com/" target="_blank"  rel="noopener noreferrer">Aba liga</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.kls.rs/" target="_blank"  rel="noopener noreferrer">Liga Srbije</a>
        </div>
        <div className="footer-links-link">
          <a href="https://www.nba.com/" target="_blank"  rel="noopener noreferrer">NBA</a>
        </div>
      </div>
    </div>
  )
}


export default Links

